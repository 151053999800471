import { SwipeableDrawer as SwipeableDrawerMui, SwipeableDrawerProps as SwipeableDrawerPropsMui } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

type SwipeableDrawerProps = SwipeableDrawerPropsMui

const StyledSwipeableDrawer= styled(SwipeableDrawerMui)({})

const SwipeableDrawer:React.FC<SwipeableDrawerProps> = props => {
    return <StyledSwipeableDrawer {...props}>
        {props.children}
    </StyledSwipeableDrawer>
}

export default SwipeableDrawer;