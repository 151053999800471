import {
	CircularProgress as CircularProgressMui,
	CircularProgressProps as CircularProgressPropsMui,
} from '@mui/material';

import styled from 'styled-components';

type CircularProgressProps = CircularProgressPropsMui;

const StyledCircularProgress = styled(CircularProgressMui)``;

const CircularProgress: React.FC<CircularProgressProps> = props => {
	return <StyledCircularProgress {...props} />;
};

export default CircularProgress;
