import {
	TableCell as TableCellMui,
	TableCellProps as TableCellPropsMui,
} from '@mui/material';

import styled from 'styled-components';

type TableCellProps = TableCellPropsMui;

const StyledTableCell = styled(TableCellMui)({});

const TableCell: React.FC<TableCellProps> = props => {
	return <StyledTableCell {...props}>{props.children}</StyledTableCell>;
};

export default TableCell;
