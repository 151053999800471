import { Select as SelectMui, SelectProps as SelectPropsMui,  } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { ReactComponent as ArrowDownIcon } from 'assett/icons/arrow_down_icon.svg';


export type SelectProps = SelectPropsMui

const StyledSelect = styled(SelectMui)({})

const Select:React.FC<SelectProps> = props => {
    return <StyledSelect  {...props}
        IconComponent={ArrowDownIcon}>
        {props.children}
    </StyledSelect >
}

export default Select;