import { Dayjs } from 'dayjs';

export interface IFilterState {
	size: number;
	search: boolean;
	searchCheckElegibility: string;
	buttonSearchCheckElegibility: boolean;
	inputFiscalCode: string;
	inputMemberId: string;
	inputFirstName: string;
	inputLastName: string;
	inputDateOfBirth: Dayjs | null;
	dateOfService: Dayjs | null;
	filterDateOfService: Dayjs | null;
	inputClaimId: string;
	inputExternalClaimId: string;
	inputClaimFirstName: string;
	inputClaimLastName: string;
	inputClaimDate: string;
	inputClaimStatus: string;
	inputReferenceNumber: number | null;
	inputTotalAmount: number | null;
	sortingFields: string;
	sortingDirections: string;
}

export interface IState {
	errorCode: number;
	errorMessage: string;
}

export interface IErrorInputResponse {
	errorCode: number;
	errorMessage: string;
}

export interface IFilterCheckEligibility
	extends IFilterState,
		IErrorInputResponse {}

export enum OptionsDatepicker {
	margin = 'margin',
	field = 'field',
}

export type TInputStateSlice = {
	reasonNote?: string | null;
	reasonCode?: string | null;
};

export enum sortingDirections {
	DESC = 'DESC',
	ASC = 'ASC',
}
