import { useMediaQuery } from '@mui/material';
import { ReactComponent as Camera } from 'assett/icons/camera_icon.svg';
import { ReactComponent as CloseDialog } from 'assett/icons/close_dialog_icon.svg';
import { ReactComponent as Folder } from 'assett/icons/folder_icon.svg';
import { ReactComponent as Landscape } from 'assett/icons/landscape_icon.svg';
import {
	UploadFileStep,
	resetUploadFileDialogState,
	setStep,
} from 'feature/hooks/uploadFileDialogSlice';
import {
	setFileUpload,
	setTypeUpload,
	uploadDocument,
} from 'feature/upload/uploadSlice';
import mobile from 'is-mobile';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { Box, Button, Dialog, IconButton, Typography } from 'styles';
import palette from 'styles/theme/Palette';
import { theme } from 'styles/theme/ThemeStyle';

/* 
enum UploadFileStep {
	CHOOSE,
	LOADING,
	COMPLETE,
} */
interface IUploadAction {
	icon: string;
	label: string;
	actionType: 'fromLibrary' | 'fromPhoto' | 'fromFile';
}

const UPLOAD_TABLET_ACTIONS: IUploadAction[] = [
	{
		icon: '',
		label: '',
		actionType: 'fromLibrary',
	},
	{
		icon: '',
		label: '',
		actionType: 'fromPhoto',
	},
	{
		icon: 'folder',
		label: '',
		actionType: 'fromFile',
	},
];

const UPLOAD_WEB_ACTIONS: IUploadAction[] = [
	{
		icon: 'folder',
		label: '',
		actionType: 'fromFile',
	},
];

const UpdateFileDialog = () => {
	const tablet = useMediaQuery(theme.breakpoints.down('lg'));
	const dispatch = useDispatch<AppDispatch>();
	const { t: tClientLabels } = useTranslation('', { keyPrefix: 'clientLabels' });
	const { open, docType, step } = useSelector(
		(store: RootState) => store.hooks.uploadFileDialog,
	);
	const isMobile = mobile();
	const allowedActions = useMemo(
		() => (isMobile ? UPLOAD_TABLET_ACTIONS : UPLOAD_WEB_ACTIONS),
		[isMobile],
	);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		if (files) {
			const selectedFile = files[0];
			dispatch(setFileUpload(selectedFile));
			dispatch(setTypeUpload(docType));
			dispatch(setStep(UploadFileStep.LOADING));
			dispatch(uploadDocument({ showloader: false }));
		}
	};

	const getIcon = (actionType: 'fromLibrary' | 'fromPhoto' | 'fromFile') => {
		switch (actionType) {
			case 'fromFile':
				return (
					<Folder
						width={tablet ? '2rem' : '3rem'}
						height={tablet ? '2rem' : '3rem'}
					/>
				);
			case 'fromLibrary':
				return <Landscape />;
			case 'fromPhoto':
				return <Camera />;
			default:
				return <Folder />;
		}
	};

	const onConfirm = () => {
		dispatch(resetUploadFileDialogState());
	};

	return (
		<Dialog
			title={tClientLabels(`uploadFileDialog.${[docType]}.title`)}
			close={
				<IconButton onClick={onConfirm}>
					<CloseDialog />
				</IconButton>
			}
			open={open}
			variant="uploadFileDialog"
			events={[UploadFileStep.COMPLETE, UploadFileStep.LOADING].includes(step)}
			actions={
				[UploadFileStep.COMPLETE, UploadFileStep.LOADING].includes(step) ? (
					<>
						<Button variant="outlined" onClick={() => {}}>
							{tClientLabels(`uploadFileDialog.buttons.cancel`)}
						</Button>
						<Button variant="contained" onClick={onConfirm}>
							{tClientLabels(`uploadFileDialog.buttons.confirm`)}
						</Button>
					</>
				) : (
					<></>
				)
			}
			type=""
		>
			<Box display="flex" gap="1rem" flexDirection="column">
				<Typography variant="body">
					{tClientLabels(`uploadFileDialog.${[docType]}.description`)}
				</Typography>
				{[UploadFileStep.CHOOSE].includes(step) ? (
					<Box
						display="flex"
						alignItems="center"
						justifyContent="center"
						gap="0.938rem"
						sx={{ width: '100%' }}
					>
						{allowedActions.map(action => (
							<Button
								key={action.actionType}
								component="label"
								variant="upload"
								sx={{
									display: 'flex',
									flexDirection: 'column',
									borderColor: palette.primary.light,
									maxHeight: '8.75rem',
									borderRadius: '0.5rem',
									width: '100%',
								}}
							>
								{getIcon(action.actionType)}
								<Typography
									variant="bodyLinkTablet"
									sx={{
										textDecoration: 'underline',
										textUnderlineOffset: '2px',
										fontSize: { lg: '14px', sm: '12px' },
										lineHeight: { lg: '16.8px', sm: '16px' },
									}}
								>
									{tClientLabels(`uploadFileDialog.actionType.${action.actionType}`)}
								</Typography>
								<input
									hidden
									id="fileInput"
									type="file"
									onChange={handleFileChange}
									accept="*"
								/>
							</Button>
						))}
					</Box>
				) : (
					<p>spinner</p>
				)}
			</Box>
		</Dialog>
	);
};

export default UpdateFileDialog;
