import dayjs from 'dayjs';
import { getLanguageStorage } from 'utils/localStorage';

export const validateEmail = (email: string) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		);
};

export const capitalize = (str: string) => {
	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const camelcase = (str: string) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const camelcaseToUnderscore = (str: string) => {
	if (!str) return '';
	return str
		.split(/(?=[A-Z])/)
		.join('_')
		.toLowerCase();
};

export const pxToRem = (px: number) => {
	return px / 16;
};

export const getStatusString = (string: string) => {
	switch (string) {
		case 'NEW':
		case 'WAITING_FEEDBACK':
		case 'APPROVED':
			return 'Pending';
		case 'MEMBER_PAID':
			return 'Paid';
		case 'COMPLETED':
			return 'Completed';
		case 'CANCELED':
			return 'Canceled';
	}
};

export const getShortDate = (date?: string) => {
	if (!date) return '';
	var d = new Date(date);
	d = new Date(d.getTime() + d.getTimezoneOffset() * 60000);

	return d.toLocaleDateString(getLanguageStorage(), {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	});
};

export const getShortTime = (date?: string) => {
	if (!date) return '';
	var d = new Date(date);
	d = new Date(d.getTime() + d.getTimezoneOffset() * 60000);

	return d
		.toLocaleTimeString([], {
			hour: '2-digit',
			minute: '2-digit',
			hour12: true,
		})
		.toUpperCase();
};

export const getFullDate = (date?: string) => {
	if (!date) return '';

	return `${getShortDate(date)} ${getShortTime(date)}`;
};

export const formatDayjsForBE = (date: dayjs.Dayjs | null) => {
	return dayjs(date).format('YYYY-MM-DD');
};

export const formatDateWithoutZulu = (date: string) => {
	return date.substring(0, date.search('T'));
};

export const dayjsForMaterial = (date?: dayjs.Dayjs | null) => {
	if (!date) return undefined;
	return date
		.set('hour', 0)
		.set('minute', 0)
		.set('second', 0)
		.set('millisecond', 0);
};

export const formatPrice = (number: number | string) => {
	const n = Number(number);
	return new Intl.NumberFormat(getLanguageStorage(), {
		style: 'currency',
		currency: 'EUR',
	})
		.format(n)
		.replace('-', '- ')
		.replace('€', '€ ');
};

export const formatPerc = (number: number) => {
	return Math.round((1 - parseFloat(number.toFixed(2))) * 100);
};

export const formatNumber = (value: string, isCurrency: boolean) => {
	const pattern = /^(:?(?:[+-]?[0-9]+([.,])?([0-9]+)??)+[+-]?)$/;

	if (value.match(pattern)) {
		let returnValue = value.replaceAll(/,/g, '.');
		const dotPosition = returnValue.search(/\./);

		if (dotPosition !== -1) {
			//se sono stati inseriti più punti andiamo ad eliminare la seconda occorrenza
			if (returnValue.split('').filter(v => v === '.')?.length > 1) {
				const newString = returnValue
					.substring(dotPosition + 1, returnValue.length)
					.replace('.', '');
				returnValue = returnValue.substring(0, dotPosition + 1) + newString;
			}
			//se sono stati inseriti 3 caratteri dopo il punto andiamo ad eliminare l'ultimo carattere
			if (dotPosition + 3 < value.length) {
				returnValue = returnValue.substring(0, dotPosition + 3);
			}
		}

		if (isCurrency) {
			returnValue = returnValue.replace('+', '').replace('-', '');
		}

		return returnValue;
	}

	return '';
};

export const formatInputNumberValue = (value: string | number) => {
	const lng = getLanguageStorage();

	if (lng.toLowerCase() === 'en-gb' || lng.toLowerCase() === 'en-us') {
		return value.toString().replace(',', '.');
	} else {
		return value.toString().replace('.', ',');
	}
};

export const copyToClipboard = (text: string) => {
	navigator.clipboard.writeText(text);
};

export const getLocaleLanguage = () => {
	return getLanguageStorage().substring(0, 2);
};
