import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { searchLocationThunk } from './searchThunk';

interface IState {
	errorCode: number;
	errorMessage: string;
	location: ILocation[];
	searchLocationValue: string;
}
export interface ILocation {
	_id: string;
	_index: string;
	_score: number;
	_source: {
		address: string;
		currentBrands: string[];
		currentLanguages: string[];
		currentServices: string[];
		name: string;
		number: string;
		openingsHour: string;
		position: number[];
		provider_id: string;
		provider_logo: string;
		provider_master: string;
		provider_network: string;
		site_url: string;
		timestamp: string;
		zip: string;
	};
	_type: string;
}

const initialState: IState = {
	errorCode: 0,
	errorMessage: '',
	location: [],
	searchLocationValue: '',
};

export const searchLocation = createAsyncThunk(
	'search/searchLocation',
	searchLocationThunk,
);

const searchSlice = createSlice({
	name: 'search',
	initialState,
	reducers: {
		setSearchLocationValue: (state, { payload }) => {
			state.searchLocationValue = payload;
		},
		resetSearch: state => {
			state.errorCode = 0;
			state.errorMessage = '';
			state.location = [];
			state.searchLocationValue = '';
		},
	},
	extraReducers: builder => {
		builder
			.addCase(searchLocation.pending, (state, payload) => {
				state.errorCode = 0;
				state.errorMessage = '';
			})
			.addCase(searchLocation.fulfilled, (state, { payload }) => ({
				...state,
				errorCode: 0,
				errorMessage: '',
				location: payload.hits.hits,
			}))
			.addCase(searchLocation.rejected, (state, { payload }: any) => {
				state.errorCode = payload.statusCode;
				state.errorMessage = payload.message;
			});
	},
});

export const { setSearchLocationValue, resetSearch } = searchSlice.actions;
export default searchSlice.reducer;
