import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Typography from 'styles/typography/Typograpy'

interface Props {
  sign: string,
}

export enum Sign { PLUS = "+", MINUS = "-", EQUAL = "=" }

const ClaimsOperationIcon = (props: Props) => {

  const [value, setValue] = useState<string>();
  useEffect(() => {
    switch (props.sign) {
      case Sign.EQUAL:
        setValue("=");
        break;
      case Sign.PLUS:
        setValue("+");
        break;
      case Sign.MINUS:
        setValue("-");
        break;
    }
    // eslint-disable-next-line
  }, [])

  return (
    <OperationIcon variant='bodyLink'>{value}</OperationIcon>
  )
}

export default ClaimsOperationIcon

const OperationIcon = styled(Typography)`
  border: 1px solid #B4B4B4;
  border-radius: 100%;
  padding: 10px;
  display: inline-flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`
