import { MenuList as MenuListMui, MenuListProps as MenuListPropsMui } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

type MenuListProps = MenuListPropsMui

const StyledMenuItem = styled(MenuListMui)`
 
`

const MenuList: React.FC<MenuListProps> = props => {
    return <StyledMenuItem {...props}>
        {props.children}
    </StyledMenuItem>
}

export default MenuList;