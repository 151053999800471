import { Button, ButtonProps, Typography } from '@mui/material';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

type Props = ButtonProps & {
	label: string;
	to: string;
};

const StyledButton = styled(Button)``;

const ButtonLinkDark: React.FC<Props> = props => {
	const navigate = useNavigate();
	return (
		<StyledButton
			{...props}
			disableElevation
			disableTouchRipple
			disableRipple
			disableFocusRipple
			variant="linkDark"
			type="button"
			onClick={() => navigate(props.to)}
		>
			<Typography variant="bodyLink">{props.label}</Typography>
		</StyledButton>
	);
};

export default ButtonLinkDark;
