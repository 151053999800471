import {
	InputAdornment as InputAdornmentMui,
	InputAdornmentProps as InputAdornmentPropsMui,
} from '@mui/material';

import styled from 'styled-components';

type InputAdornmentProps = InputAdornmentPropsMui;

const StyledInputAdornment = styled(InputAdornmentMui)({});

const InputAdornment: React.FC<InputAdornmentProps> = props => {
	return (
		<StyledInputAdornment {...props} sx={{ outline: 'none' }}>
			{props.children}
		</StyledInputAdornment>
	);
};

export default InputAdornment;
