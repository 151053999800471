import {
	DialogContent as DialogContentMui,
	DialogContentProps as DialogContentPropsMui,
} from '@mui/material';

import styled from 'styled-components';

type DialogContentProps = DialogContentPropsMui;

const StyledDialogContent = styled(DialogContentMui)<DialogContentProps>``;

const DialogContent: React.FC<DialogContentProps> = props => {
	return <StyledDialogContent {...props}>{props.children}</StyledDialogContent>;
};

export default DialogContent;
