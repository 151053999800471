import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IUserError } from 'types/User';
import { cleanupStorage } from 'utils/localStorage';
import { logoutThunk } from './userThunk';

interface State {
	error: IUserError;
}

const initialState: State = {
	error: {
		status: '',
		code: '',
		message: '',
	},
};

export const logout = createAsyncThunk('user/logout', logoutThunk);

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		logout: state => {
			cleanupStorage();
		},
	},
	extraReducers: builder => {
		builder.addCase(logout.fulfilled, (state, { meta }) => {
			cleanupStorage();
			const { instance, accounts } = meta.arg;
			instance.clearCache();
			instance.logoutRedirect({
				account: instance.getAccountByHomeId(accounts[0].homeAccountId),
			});

			return { ...state, logoutMsal: true };
		});
	},
});

export default userSlice.reducer;
