import { MaterialDesignContent, SnackbarProviderProps } from 'notistack';

import CloseIcon from '@mui/icons-material/Close';
import { colors } from './Palette';
import styled from 'styled-components';

const SnackBar = styled(MaterialDesignContent)(() => ({
	'&.notistack-MuiContent-success': {
		backgroundColor: colors.success,
	},
	'&.notistack-MuiContent-error': {
		backgroundColor: colors.error,
	},
	'.MuiSvgIcon-root': {
		marginRight: '1rem',
	},
}));

export const notistackConfig: SnackbarProviderProps = {
	Components: {
		success: SnackBar,
		error: SnackBar,
	},
	iconVariant: {
		error: <CloseIcon />,
	},
};
