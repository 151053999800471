import { useMediaQuery, useTheme } from "@mui/material";

const useDevice = () => {
    const theme = useTheme();

    return { 
        isDesktop: useMediaQuery(theme.breakpoints.up('lg')),
        isTablet: useMediaQuery(theme.breakpoints.down('lg')),
    }
};

export default useDevice;