import { ListItemButton as ListItemButtonMui, ListItemButtonProps as ListItemButtonPropsMui } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

type ListItemButtonProps = ListItemButtonPropsMui

const StyledListItemButton = styled(ListItemButtonMui)``

const ListItemButton:React.FC<ListItemButtonProps> = props => {
    return <StyledListItemButton {...props} disableRipple disableTouchRipple>
        {props.children}
    </StyledListItemButton>
}

export default ListItemButton;