import FileSaver from 'file-saver';

export const download = async (
	blob: any,
	fileName: string,
	fileExtension?: string,
) => {
	const newBlob = blob.slice(0, blob.size, 'application/octet-stream');

	var url = URL.createObjectURL(newBlob);
	FileSaver.saveAs(
		url,
		`${fileName}${
			fileExtension ? `.${fileExtension.toString().toLowerCase()}` : ''
		}`,
	);
};

export const exportListToExcel = async (
	data: any,
	type: string,
	ext: string,
) => {
	const fileName = `Download_${type}`;
	await download(data, fileName, ext);
};

//verifies if file is accepted
export function verifyAccept(type: string, accept: string): boolean {
	const allowed = accept
		.replace(' ', '')
		.split(',')
		.map(x => x.trim());
	return allowed.includes(type) || allowed.includes(type.split('/')[0] + '/*');
}
