import {
	changePage,
	getClaims,
	getClaimsStatus,
	resetClaims,
	setSearch,
} from 'feature/claims/claimsSlice';
import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { resetForm, setSortingValues } from 'feature/form/formSlice';
import useDevice from 'hooks/DesktopDevice.hook';
import { RootState } from 'store/store';
import BoxOverflow from 'styles/box/BoxOverflow';
import ClaimListFooter from './ClaimListFooter.component';
import Claims from './Claims';

const ViewClaims = () => {
	const dispatch = useDispatch<any>();
	const {
		inputClaimId,
		inputExternalClaimId,
		inputFirstName,
		inputLastName,
		dateOfService,
		inputClaimStatus,
		sortingDirections,
		sortingFields,
	} = useSelector((store: RootState) => store.form);
	const { isDesktop } = useDevice();

	useEffect(() => {
		dispatch(resetForm());
		dispatch(resetClaims());
		dispatch(setSearch(false));
		dispatch(
			setSortingValues({
				name: 'serviceDate,externalClaimId',
				value: 'DESC,DESC',
			}),
		);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (sortingFields) {
			dispatch(changePage(1));
			dispatch(getClaims(''));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		dateOfService,
		inputClaimId,
		inputExternalClaimId,
		inputClaimStatus,
		inputFirstName,
		inputLastName,
		sortingDirections,
		sortingFields,
	]);

	return (
		<Fragment>
			<BoxOverflow
				variant="xy"
				sx={{ maxWidth: '100%', height: !!isDesktop ? 720 : 490 }}
			>
				<Claims />
			</BoxOverflow>
			<ClaimListFooter />
		</Fragment>
	);
};

export default ViewClaims;
