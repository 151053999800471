import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

import { download } from '../../utils/download';

export const downloadFileThunk = async (
	{ url, fileName }: { url: string; fileName: string },
	thunkAPI: any,
) => {
	try {
		const response = await authFetch.get(url, {
			responseType: 'blob',
		});
		download(response.data, fileName);
		return true;
	} catch (error: any) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
