const b2cPolicies = {
	names: {
		signUpSignIn: process.env.REACT_APP_AZURE_SIGN_IN_SIGN_UP!,
	},
	authorities: {
		signUpSignIn: {
			authority: process.env.REACT_APP_AZURE_AUTHORITY!,
		},
	},
	authorityDomain: process.env.REACT_APP_AZURE_AUTHORITY_DOMAIN!,
};

export const msalConfig = {
	auth: {
		clientId: process.env.REACT_APP_AZURE_CLIENT_ID!, // This is the ONLY mandatory field that you need to supply.
		authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
		knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
		redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI!, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
		postLogoutRedirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI!, // Indicates the page to navigate after logout.
	},
	cache: {
		cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
		storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
	},
};
