import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DocType } from "types/Upload";

/* type DocType =  'prescription' | 'receipt' | 'attestation' | '' */


export enum UploadFileStep {
	CHOOSE,
	LOADING,
	COMPLETE,
}


interface IState {
    open: boolean;
	docType: DocType
	step:UploadFileStep
    
}


const initialState: IState = {
    open: false,
    docType:'',
	step: UploadFileStep.CHOOSE
    
}


const uploadFileDialogSlice = createSlice({
	name: 'uploadFileDialog',
	initialState,
	reducers: {
	 	setDialogOpen: (state, isOpen:PayloadAction<boolean>) => {
			state.open = isOpen.payload;
		},
	 	setDocType: (state, docType:PayloadAction<DocType>) => {
			state.docType = docType.payload;
		},
	 	setStep: (state, step:PayloadAction<UploadFileStep>) => {
			state.step = step.payload;
		},
	 	resetUploadFileDialogState: (state) => {
			  state.open = false;
			  state.docType = '';
			  state.step = UploadFileStep.CHOOSE
			
		},
	/*	setDialogEventCancel: state => {
			state.eventCancel = true;
			state.open = false;
			state.eventConfirm = false;
			state.title = '';
			state.content = '';
			state.labelCancel = '';
			state.labelConfirm = '';
		},
		setDialogEventConfirm: state => {
			state.eventConfirm = true;
			state.open = false;
			state.eventCancel = false;
			state.title = '';
			state.content = '';
			state.labelCancel = '';
			state.labelConfirm = '';
		},
		setInjectionState: (state, { payload }) => {
			//state.open = true;
			state.title = payload.title;
			state.content = payload.content;
			state.labelCancel = payload.labelCancel;
			state.labelConfirm = payload.labelConfirm;
			state.variantButtonCancel = payload.variantButtonCancel;
			state.events = payload.events;
			state.multi = payload?.multi ? payload.multi : false;
			state.data = payload?.data ? payload.data : [];
			state.type = payload.type;
		},
		resetDialogConfirm: () => initialState, */
	},
});

export const {
    setDialogOpen,
	setDocType,
	setStep,
	resetUploadFileDialogState

} = uploadFileDialogSlice.actions;
export default uploadFileDialogSlice.reducer;
