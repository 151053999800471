import {
	resetDialogConfirm,
	setDialogOpen,
	setInjectionState,
} from 'feature/hooks/dialogSlice';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';

import { resetLimitations } from 'feature/limitations/limitationsSlice';
import i18next from 'i18next';
import packageJson from '../../package.json';
import { useLogout } from './login.hook';

export const SHOW_LIMITATIONS = 'showLimitations';
export const COOKIES_DIALOG = 'cookiesDialog';
export const VISION_CHANGE_ALERT = 'visionChangeAlert';
export const NO_MAT_NO_EXAM = 'noMatNoExam';
export const SUBMIT_LATER = 'submitLater';
export const ERROR_MESSAGE = 'errorMessage';
export const NO_PROVIDER = 'noProvider';
export const CHECK_CONNECTION_ERROR = 'noProvider';
export const GENERIC_ERROR = 'genericError';
export const VERSION = 'version';
export const TOKEN_EXPIRED = 'tokenExpired';

const useDialogConfirm = () => {
	const dispatch = useDispatch<AppDispatch>();

	const { limitations } = useSelector((state: RootState) => state.limitations);
	const { type } = useSelector((store: RootState) => store.hooks.dialog);

	const mapping = useCallback(
		(type: string, text: string) => {
			switch (type) {
				case SHOW_LIMITATIONS:
					return {
						title: i18next.t('clientLabels.dialog.confirm.showLimitations.title'),
						content: i18next.t('clientLabels.dialog.confirm.showLimitations.content'),
						multi: true,
						data: limitations.map((e: any) => ({
							title: e.attributes.title,
							content: e.attributes.body,
						})),
						type: type,
						buttonClose: true,
					};
				case VISION_CHANGE_ALERT:
					//triggered by slice. not configured here
					return {};
				case NO_MAT_NO_EXAM:
					return {
						title: i18next.t('clientLabels.dialog.confirm.noMatNoExam.title'),
						content: i18next.t('clientLabels.dialog.confirm.noMatNoExam.content'),
						type: type,
						buttonClose: true,
					};
				case SUBMIT_LATER:
					return {
						title: i18next.t('clientLabels.dialog.confirm.submitLater.title'),
						content: i18next.t('clientLabels.dialog.confirm.submitLater.content'),
						labelCancel: i18next.t('clientLabels.dialog.buttons.cancel'),
						labelConfirm: i18next.t('clientLabels.dialog.buttons.continue'),
						variantButtonCancel: 'outlined',
						type: type,
						buttonClose: true,
					};
				case GENERIC_ERROR:
					return {
						title: i18next.t('clientLabels.dialog.confirm.error.title'),
						content: text || i18next.t('clientLabels.dialog.confirm.error.content'),
						labelCancel: i18next.t('clientLabels.dialog.buttons.cancel'),
						variantButtonCancel: 'contained',
						type: type,
						buttonClose: true,
					};
				case VERSION:
					return {
						title: i18next.t('clientLabels.dialog.version.title'),
						content: `${i18next.t('clientLabels.dialog.version.contentTitle')} ${
							packageJson.version
						}`,
						type: 'versionModal',
						buttonClose: true,
					};
				default:
					break;
			}
		},
		[limitations],
	);

	const handleLogout = useLogout();

	const closeDialog = useCallback(() => {
		dispatch(setDialogOpen(false));
		dispatch(resetDialogConfirm());

		switch (type) {
			case SHOW_LIMITATIONS:
				dispatch(resetLimitations());
				break;
			case TOKEN_EXPIRED:
				handleLogout();
				break;
			default:
				break;
		}
	}, [dispatch, handleLogout, type]);

	const handleOpen = useCallback(
		(type: string, text?: string) => {
			dispatch(setInjectionState(mapping(type, text || '')));
			dispatch(setDialogOpen(true));
		},
		[dispatch, mapping],
	);

	const handleConfirm = useCallback(() => {
		dispatch(resetDialogConfirm());
		switch (type) {
			case NO_PROVIDER:
			case CHECK_CONNECTION_ERROR:
			case TOKEN_EXPIRED:
				handleLogout();
				break;
			default:
				break;
		}
		dispatch(setDialogOpen(false));
	}, [dispatch, handleLogout, type]);

	return useMemo(
		() => ({
			open: handleOpen,
			close: closeDialog,
			confirm: handleConfirm,
		}),
		[closeDialog, handleConfirm, handleOpen],
	);
};

export default useDialogConfirm;
