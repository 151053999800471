import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { uploadDocumentThunk } from './uploadThunk';
import { DocType, IUploadState } from 'types/Upload';

const initialState: IUploadState = {
	errorCode: 0,
	errorMessage: '',
	type: '',
	file: null,
	prescription: {
		prescriptionId: 0,
		fileName: '',
		resourceUrl: '',
	},
	attestation: {
		attestationId: 0,
		fileName: '',
		resourceUrl: '',
	},
	receipt: {
		receiptId: 0,
		fileName: '',
		resourceUrl: '',
	},
};

export const uploadDocument = createAsyncThunk(
	'upload/uploadDocument',
	uploadDocumentThunk,
);

const uploadSlice = createSlice({
	name: 'upload',
	initialState,
	reducers: {
		setTypeUpload: (state, { payload }: PayloadAction<DocType>) => {
			state.type = payload;
		},
		setFileUpload: (state, { payload }: PayloadAction<File>) => {
			state.file = payload;
		},
		resetUpload: () => initialState,
	},
	extraReducers: builder => {
		builder
			.addCase(uploadDocument.fulfilled, (state, { payload }: any) => {
				state[state.type || 'prescription'] = { ...payload };
			})
			.addCase(uploadDocument.rejected, (state, { payload }: any) => { });
	},
});

export const { setFileUpload, setTypeUpload } = uploadSlice.actions;
export default uploadSlice.reducer;
