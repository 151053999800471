import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

export const getLimitationsThunk = async (_: any, thunkAPI: any) => {
	const lang = 'en';

	const { member: { benefit } } = thunkAPI.getState().member

	const getLimitationsURL = `/cms/api/limitations?locale=${lang}&populate=*&filters[plan_name][%24eq]=${benefit.planId}`;

	try {
		const response = await authFetch.get(getLimitationsURL);
		return response.data.data;
	} catch (error: any) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
