import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getLimitationsThunk } from './limitationThunk';
import { IState } from 'types/Limitations';

// interface ILimitationsData {
// 	_id: string;
// 	attributes: any;
// }

const initialState: IState = {
	errorCode: 0,
	errorMessage: '',
	limitations: [],
};

export const getLimitations = createAsyncThunk(
	'limitations/getLimitations',
	getLimitationsThunk,
);

const limitationsSlice = createSlice({
	name: 'limitations',
	initialState,
	reducers: {
		resetLimitations: state => {
			state.errorCode = 0;
			state.errorMessage = '';
			state.limitations = [];
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getLimitations.fulfilled, (state, { payload }) => {
				state.errorCode = 0;
				state.errorMessage = '';
				state.limitations = payload;
			})
			.addCase(getLimitations.rejected, (state, { payload }) => { });
	},
});

export const { resetLimitations } = limitationsSlice.actions;
export default limitationsSlice.reducer;
