import { AppDispatch } from 'store/store';
import { downloadFile } from 'feature/utils/downloaderSlice';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const useFileOpener = () => {
	const dispatch = useDispatch<AppDispatch>();
	return useCallback(
		(fileUrl: string, fileName: string = 'download') => {
			// if (
			// 	fileName.split('.').length === 2 &&
			// 	['pdf', 'png', 'gif', 'jpg', 'jpeg'].includes(fileName.split('.').pop()!)
			// ) {
			// 	window.open(fileUrl, '_blank');
			// } else {
			dispatch(downloadFile({ url: fileUrl, fileName }));
			// }
		},
		[dispatch],
	);
};
