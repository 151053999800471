import { ReactComponent as ChevronBreadCrumbIcon } from 'assett/icons/chevron_breadcrumb_icon.svg';
import { ReactComponent as ChevronBreadCrumbIconTablet } from 'assett/icons/chevron_breadcrumb_icon_tablet.svg';
import useDevice from 'hooks/DesktopDevice.hook';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BreadcrumbsMui from 'styles/breadcrumbs/Breadcrumbs';
import Button from 'styles/button/Button';
import Typography from 'styles/typography/Typograpy';

interface Props {
	claimId: string;
}

const Breadcrumbs = (props: Props) => {
	const location = useLocation();
	const navigate = useNavigate();
	const pathnames = location.pathname.split('/');
	const { t: tClientLabels } = useTranslation('', { keyPrefix: 'clientLabels' });
	const { isDesktop } = useDevice();

	const { id } = useParams();

	return (
		<BreadcrumbsMui
			sx={{ cursor: 'pointer', mt: 2.5 }}
			separator={
				isDesktop ? <ChevronBreadCrumbIcon /> : <ChevronBreadCrumbIconTablet />
			}
		>
			{pathnames
				.map(e => (decodeURI(e) === id ? id : tClientLabels(`${e}.pageTitle`)))
				.map((route: any, index: number) => {
					if (index === 0) {
						return (
							<Button
								variant="link"
								sx={{ minWidth: 0 }}
								onClick={() => navigate('/dashboard')}
								key={index}
							>
								<Typography variant="value3" sx={{ color: 'grey2.main' }}>
									{'Home'}
								</Typography>
							</Button>
						);
					}
					return (
						<Button
							variant="link"
							sx={{ minWidth: 0 }}
							onClick={() => {
								const path = [];

								for (let i = 1; i <= index; i++) {
									path.push(`/${pathnames[i]}`);
								}
								navigate(path.join(''));
							}}
							key={index}
						>
							<Typography
								variant="value3"
								sx={{
									color: index === pathnames.length - 1 ? 'primary.main' : 'grey2.main',
								}}
							>
								{pathnames[1] === 'view-claims' &&
								index > 1 &&
								index === pathnames.length - 1
									? `Claim #${props.claimId ? props.claimId : route}`
									: pathnames[1] === 'view-payment-history' &&
									  index > 1 &&
									  index === pathnames.length - 1
									? `Payment #${route}`
									: route}
							</Typography>
						</Button>
					);
				})}
		</BreadcrumbsMui>
	);
};

export default Breadcrumbs;
