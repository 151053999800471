import { useTranslation } from 'react-i18next';

import { CompositeTypography } from 'styles';
import Paper from 'styles/paper/paper';
import Stack from 'styles/stack/Stack';
import { IClaim } from 'types/Claim';
import ClaimStatusBreadcrumbs from './ClaimStatusBreadcrumbs.component';

const ClaimMemberDetails = ({ claim }: { claim: IClaim }) => {
	const { t: tClientLabels } = useTranslation('', { keyPrefix: 'clientLabels' });
	const { t: tDbLabels } = useTranslation('', { keyPrefix: 'dbLabels' });

	return (
		<Paper
			elevation={0}
			sx={{
				p: 2.5,
				justifyContent: 'space-between',
				display: 'flex',
				borderRadius: 0.5,
				width: '100%',
				maxWidth: '1500px',
			}}
		>
			<Stack flexGrow={1} rowGap={1.875}>
				<CompositeTypography label={tClientLabels('claim.labels.memberFullName')} value={claim?.memberFullName as string} />
				<CompositeTypography label={tClientLabels('claim.labels.memberId')} value={claim?.memberId as string} />
				<CompositeTypography label={tClientLabels('claim.labels.memberDesignation')} value={tDbLabels(claim?.memberDesignationLabel as string)} />
			</Stack>
			<Stack flexGrow={0} width="auto" minWidth={'40%'} rowGap={1.875}>
				<CompositeTypography label={tClientLabels('claim.labels.planName')} value={claim?.planName as string} />
				<CompositeTypography label={tClientLabels('claim.labels.memberProviderLocation')} value={claim?.memberProviderLocation as string} />
				<CompositeTypography label={tClientLabels('claim.labels.status')} value={claim?.claimStatus as string} children={<ClaimStatusBreadcrumbs claimStatusId={claim.claimStatusId} />} />


			</Stack>
		</Paper>
	);
};

export default ClaimMemberDetails;
