import {
	TableRow as TableRowMui,
	TableRowProps as TableRowPropsMui,
} from '@mui/material';

import styled from 'styled-components';

type TableRowProps = TableRowPropsMui;

const StyledTableRow = styled(TableRowMui)({});

const TableRow: React.FC<TableRowProps> = props => {
	return <StyledTableRow {...props}>{props.children}</StyledTableRow>;
};

export default TableRow;
