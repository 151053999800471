import {
	createSlice,
	isAnyOf,
	isFulfilled,
	isPending,
	isRejected,
	isRejectedWithValue,
} from '@reduxjs/toolkit';

import type { AnyAction } from '@reduxjs/toolkit';

//************************************************* */
// README:
// when doing an asyncThunk use
//	-  showloader = false if you don't want to use default busy indicator
//	-  loadingMessage params to show a message under busy indicator
//************************************************* */

export type thunkLoadingParams = {
	showloader?: boolean;
	loadingMessage?: string;
};

const initialState: {
	isLoading: boolean;
	loadingMessage: string;
	pendingProcesses: AnyAction['type'][];
} = {
	isLoading: false,
	loadingMessage: '',
	pendingProcesses: [],
};

const prorgessIndicator = createSlice({
	name: 'prorgessIndicator',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addMatcher(isPending, (state, action: AnyAction) => {
				const { loadingMessage, showloader = true } = action.meta.arg || {};

				state.loadingMessage = loadingMessage;

				if (showloader) {
					const thunkName = action.type.split('/').slice(0, -1).join('/');
					state.pendingProcesses.push(thunkName);
				}
				state.isLoading = !!state.pendingProcesses.length;
			})
			.addMatcher(
				isAnyOf(isFulfilled, isRejected, isRejectedWithValue),
				(state, action: AnyAction) => {
					const thunkName = action.type.split('/').slice(0, -1).join('/');
					const indToDelete = state.pendingProcesses.indexOf(thunkName);
					if (indToDelete !== -1) {
						state.pendingProcesses.splice(indToDelete, 1);
					}
					state.isLoading = !!state.pendingProcesses.length;
				},
			);
	},
});

export default prorgessIndicator.reducer;
