import {
	FormLabel as FormLabelMui,
	FormLabelProps as FormLabelPropsMui,
} from '@mui/material';

import { FC } from 'react';
import styled from 'styled-components';

type FormLabelProps = FormLabelPropsMui;

const StyledFormLabel = styled(FormLabelMui)({});

const FormLabel: FC<FormLabelProps> = props => {
	return <StyledFormLabel {...props} />;
};

export default FormLabel;
