import * as React from 'react';

import {
	Switch as SwitchMui,
	SwitchProps as SwitchPropsMui,
} from '@mui/material';

import FormControlLabel from 'styles/form/FormControlLabel';
import styled from 'styled-components';

type SwitchProps = SwitchPropsMui & { label: string };

const StyledSwitch = styled(SwitchMui)({});

const Switch: React.FC<SwitchProps> = props => {
	return (
		<FormControlLabel
			label={props.label}
			labelPlacement="start"
			sx={{ mr: 2 }}
			slotProps={{
				typography: { variant: 'value2' },
			}}
			control={<StyledSwitch {...props} />}
		/>
	);
};

export default Switch;
