import styled, { css } from 'styled-components';
import Box from 'styles/box/Box';
import { BrowserRouter } from 'react-router-dom';
import CircularProgress from 'styles/circularProgress/CircularProgress';
import { RootState } from 'store/store';
import { Router } from './Router';
import Typography from 'styles/typography/Typograpy';
import { useSelector } from 'react-redux';
import { useMediaQuery, useTheme } from '@mui/material';

interface Props {
	className?: string;
}

const Overlay = styled(Box)`
	${({ theme }) => css`
		width: 100vw;
		height: 100vh;
		display: flex;
		position: absolute;
		justify-content: center;
		align-items: center;
		background-color: ${theme.palette.grey4.main};
		opacity: 0.3;
		z-index: 999999;
		flex-direction: column;
		row-gap: 1rem;
	`}
`;

export const Frame: React.FC<Props> = (props = {}) => {
	const progressIndicator = useSelector(
		(state: RootState) => state.progressIndicator,
	);

	const theme = useTheme();
	const desktop = useMediaQuery(theme.breakpoints.up('lg'));

	return (
		<>
			{progressIndicator?.isLoading ? (
				<Overlay>
					<CircularProgress color="inherit" />
					<Typography variant="subheadline1" sx={{ width: '80%' }}>
						{progressIndicator?.loadingMessage}
					</Typography>
				</Overlay>
			) : null}
			<Box
				sx={{
					width: '100vw',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Box
					maxWidth={'lg'}
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						width: '100%',
						height: '100%',
						marginLeft: !!desktop ? '160px' : '120px',
						marginRight: !!desktop ? '100px' : '60px',
					}}
				>
					<BrowserRouter>
						<Router />
					</BrowserRouter>
				</Box>
			</Box>
		</>
	);
};
