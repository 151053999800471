import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

export const logoutThunk = async (
	_: { instance: IPublicClientApplication; accounts: AccountInfo[] },
	thunkAPI: any,
) => {
	try {
		const response = await authFetch.post('/logout', {});
		return response.data;
	} catch (error: any) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
