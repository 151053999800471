import { combineReducers, configureStore } from '@reduxjs/toolkit';

import claimReducer from '../feature/claims/claimSlice';
import claimsReducer from '../feature/claims/claimsSlice';
import dialogReducer from 'feature/hooks/dialogSlice';
import formReducer from '../feature/form/formSlice';
import healthCheckReducer from '../feature/utils/checkConnectionSlice';
import limitationsReducer from '../feature/limitations/limitationsSlice';
import locationHookSlice from 'feature/hooks/locationSlice';
import progressIndicatorReducer from 'feature/progressIndicator/progressIndicatorSlice';
import searchReducer from '../feature/search/searchSlice';
import uploadFileDialogSlice from '../feature/hooks/uploadFileDialogSlice';
import uploadReducer from '../feature/upload/uploadSlice';
import userReducer from '../feature/user/userSlice';
import inputReducer from '../feature/input/inputSlice';
import textsLocalesReducer from '../feature/textsLocales/textsLocalesSlice';

const rootReducer = {
	user: userReducer,
	progressIndicator: progressIndicatorReducer,
	search: searchReducer,
	hooks: combineReducers({
		dialog: dialogReducer,
		location: locationHookSlice,
		uploadFileDialog: uploadFileDialogSlice,
	}),
	limitations: limitationsReducer,
	claims: claimsReducer,
	claim: claimReducer,
	form: formReducer,
	input: inputReducer,
	upload: uploadReducer,
	healthCheck: healthCheckReducer,
	textsLocales: textsLocalesReducer,
};

export const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
