import { ButtonPropsVariantOverrides } from '@mui/material/Button';
import { OverridableStringUnion } from '@mui/types';
import { createSlice } from '@reduxjs/toolkit';

interface IState {
	type: string;
	open: boolean;
	title: string;
	content: string;
	buttonCancel: boolean;
	buttonClose: boolean;
	eventSave: boolean;
	labelButtonCancel: string;
	labelButtonSave: string;
	variantButtonCancel: OverridableStringUnion<
		'text' | 'outlined' | 'contained',
		ButtonPropsVariantOverrides
	>;
}

const initialState: IState = {
	type: '',
	open: false,
	title: '',
	content: '',
	buttonClose: false,
	buttonCancel: false,
	eventSave: false,
	labelButtonCancel: '',
	labelButtonSave: '',
	variantButtonCancel: 'outlined',
};

const LocationHookSlice = createSlice({
	name: 'hooks-location',
	initialState,
	reducers: {
		setDialogLocationType: (state, { payload }) => {
			state.type = payload;
		},
		setDialogLocationOpen: (state, { payload }) => {
			state.open = payload;
		},
		setDialogEventCancel: state => {
			state.buttonCancel = true;
			state.open = false;
			state.eventSave = false;
			state.title = '';
			state.content = '';
			state.labelButtonCancel = '';
			state.labelButtonSave = '';
		},
		setDialogEventConfirm: state => {
			state.eventSave = true;
			state.open = false;
			state.buttonCancel = false;
			state.title = '';
			state.content = '';
			state.labelButtonCancel = '';
			state.labelButtonSave = '';
		},
		setInjectionState: (state, { payload }) => {
			state.title = payload.title;
			state.content = payload.content;
			state.labelButtonCancel = payload.labelButtonCancel;
			state.labelButtonSave = payload.labelButtonSave;
			state.variantButtonCancel = payload.variantButtonCancel;
			state.buttonClose = payload.buttonClose;
			state.buttonCancel = payload.buttonCancel;
		},
		resetDialogLocation: () => initialState,
	},
});

export const {
	setDialogLocationOpen,
	setDialogEventCancel,
	setDialogEventConfirm,
	setInjectionState,
	resetDialogLocation,
	setDialogLocationType,
} = LocationHookSlice.actions;
export default LocationHookSlice.reducer;
