import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getLocaleTranslationsThunk } from './textsLocalesThunk';

interface IState {
	errorMessages?: object;
	clientLabels: object;
	dbLabels: object;
}

const initialState: IState = {
	errorMessages: undefined,
	clientLabels: {},
	dbLabels: {},
};

export const getLocaleTranslations = createAsyncThunk(
	'textsLocales/getLocaleTranslations',
	getLocaleTranslationsThunk,
);

const textsLocalesSlice = createSlice({
	name: 'textsLocales',
	initialState,
	reducers: {
		resetTextsLocales: () => initialState,
	},
	extraReducers: builder => {
		builder.addCase(getLocaleTranslations.fulfilled, (state, { payload }) => {
			return {
				...state,
				errorMessages: payload.errors,
				clientLabels: payload.clientLabels,
				dbLabels: payload.dbLabels,
			};
		});
	},
});

export const { resetTextsLocales } = textsLocalesSlice.actions;
export default textsLocalesSlice.reducer;
