import { Icon as IconMui, IconProps as IconPropsMui } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

type IconProps = IconPropsMui

const StyledIcon = styled(IconMui)({})

const Icon:React.FC<IconProps> = props => {
    return <StyledIcon {...props}>
        {props.children}
    </StyledIcon>
}

export default Icon;