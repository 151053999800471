import { RadioGroup as RadioGroupMui, RadioGroupProps as RadioGroupPropsMui } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

type RadioGroupProps = RadioGroupPropsMui

const StyledRadioGroup = styled(RadioGroupMui)({})

const RadioGroup:React.FC<RadioGroupProps> = props => {
    return <StyledRadioGroup {...props} />
}

export default RadioGroup;