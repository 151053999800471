import {
	TextField as TextFieldMui,
	TextFieldProps as TextFieldPropsMui,
} from '@mui/material';

import InputAdornment from './InputAdornment';
import { formatNumber } from 'utils/utils';
import styled from 'styled-components';

type TextFieldProps = TextFieldPropsMui & {
	isCurrency?: boolean;
	handleInputNumberChange: (value: string) => void;
};

const StyledTextField = styled(TextFieldMui)({});

const InputNumber: React.FC<TextFieldProps> = props => {
	const { isCurrency = false, handleInputNumberChange, ...restProps } = props;

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const formattedValue = formatNumber(e.target.value, isCurrency);

		if (formattedValue === null) {
			return;
		}

		handleInputNumberChange(formattedValue);
	};

	return (
		<StyledTextField
			id={props.id}
			value={props.value}
			type="text"
			InputProps={{
				disableUnderline: true,
				endAdornment: isCurrency ? (
					<InputAdornment position="end">€</InputAdornment>
				) : (
					''
				),
			}}
			variant="filled"
			onChange={handleChange}
			{...restProps}
		>
			{props.children}
		</StyledTextField>
	);
};

export default InputNumber;
