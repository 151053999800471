import Stack from 'styles/stack/Stack';
import ClaimsHeader from './ClaimsHeader.component';
import { ClaimsList } from './ClaimsList.component';
import useDevice from 'hooks/DesktopDevice.hook';

const Claims = () => {
	const { isDesktop } = useDevice()

	return (
		<Stack width={'100%'} sx={{ mt: !!isDesktop ? 6.75 : 1 }} >
			<ClaimsHeader />
			<ClaimsList />
		</Stack>
	);
};

export default Claims;
