import {
	FilledInput,
	FormControl,
	FormHelperText,
	FormHelperTextProps,
	InputLabel,
	InputLabelProps,
	InputProps,
	TextFieldProps as TextFieldPropsMui,
} from '@mui/material';

import { ReactComponent as HidePassword } from 'assett/icons/hide_password.svg';
import IconButton from 'styles/button/IconButton';
import InputAdornment from 'styles/input/InputAdornment';
import { ReactComponent as ShowPassword } from 'assett/icons/show_password.svg';
import styled from 'styled-components';
import { useState } from 'react';

type TextFieldProps = TextFieldPropsMui;

const StyledInput = styled(FilledInput)`
	border: ${({ theme, error }) =>
		`1px solid ${error ? theme.palette.error.main : theme.palette.grey4.main}}`};
	border-radius: 4px;
	height:56px,
	${({ type }) =>
		`${type === 'password'
			? `
					font-family: Verdana;
					letter-spacing: 0.125em;
				`
			: ''
		}}`};
`;

const StyledInputLabel = styled(InputLabel)``;

const StyledInputAdornment = styled(InputAdornment)`
	padding-right: 23px;
	padding-left: 23px;
	width: 16px;
	height: 10px;
`;

const StyledFormHelperText = styled(FormHelperText)``;

const TextField: React.FC<TextFieldProps> = props => {
	const { helperText, ...TextFieldProps } = props;

	const labelProps = { ...TextFieldProps } as InputLabelProps;
	const inputProps = { ...TextFieldProps } as InputProps;
	const textProps = { ...TextFieldProps } as FormHelperTextProps;

	const [hidePassword, setHidePassword] = useState(true);

	return (
		<FormControl sx={{ width: '100%', height: 56 }} variant="filled">
			<StyledInputLabel {...labelProps}>{props.label}</StyledInputLabel>
			<StyledInput
				{...inputProps}
				type={props.type === 'password' && hidePassword ? 'password' : 'text'}
				endAdornment={
					props.type === 'password' ? (
						<StyledInputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={() => {
									setHidePassword(prev => !prev);
								}}
								edge="end"
							>
								{hidePassword ? (
									<ShowPassword width="16px" height="16px" />
								) : (
									<HidePassword width="16px" height="16px" />
								)}
							</IconButton>
						</StyledInputAdornment>
					) : null
				}
			></StyledInput>
			{props.helperText ? (
				<StyledFormHelperText {...textProps}>{helperText}</StyledFormHelperText>
			) : null}
		</FormControl>
	);
};

export default TextField;
