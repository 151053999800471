import {
	DialogActions as DialogActionsMui,
	DialogActionsProps as DialogActionsPropsMui,
} from '@mui/material';

import styled from 'styled-components';

type DialogActionsProps = DialogActionsPropsMui;

const StyledDialogActions = styled(DialogActionsMui) <DialogActionsProps>``;

const DialogActions: React.FC<DialogActionsProps> = props => {

	return <StyledDialogActions {...props}>{props.children}</StyledDialogActions>;
};

export default DialogActions;
