import { ListItemIcon as ListItemIconMui, ListItemIconProps as ListItemIconPropsMui } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

type ListItemIconProps = ListItemIconPropsMui

const StyledListItemIcon = styled(ListItemIconMui)``

const ListItemIcon:React.FC<ListItemIconProps> = props => {
    return <StyledListItemIcon {...props}>
        {props.children}
    </StyledListItemIcon>
}

export default ListItemIcon;