import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

import { thunkLoadingParams } from 'feature/progressIndicator/progressIndicatorSlice';

export const uploadDocumentThunk = async (
	arg: thunkLoadingParams,
	thunkAPI: any,
) => {
	const { type, file } = thunkAPI.getState().upload;
	/* const { docType } = thunkAPI.getState().hooks.uploadFileDialog; */

	let sendFileURL = '';

	switch (type) {
		case 'prescription':
			sendFileURL = `/prescription/uploadPrescription?memberId=1`;
			break;
		case 'receipt':
			sendFileURL = `/receipt/uploadReceipt?memberId=1`;
			break;
		case 'attestation':
			sendFileURL = `/attestation/uploadRxAttestation?memberId=1`;
			break;
	}

	let formData = new FormData();
	formData.append('image', file);
	try {
		const response = await authFetch.post(sendFileURL, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		return response.data;
	} catch (error: any) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
