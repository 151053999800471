import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { healthCheckThunk } from './checkConnectionThunk';

export const healthCheck = createAsyncThunk(
	'download/healthCheck',
	healthCheckThunk,
);

const initialState: {
	alive?: boolean;
} = {
	alive: undefined,
};

const healthCheckSlice = createSlice({
	name: 'healthCheck',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(healthCheck.fulfilled, (state, { payload }) => {
				const resultStatus = { ...state, alive: true };
				return resultStatus;
			})
			.addCase(healthCheck.rejected, (state, { payload }) => {
				const resultStatus = { ...state, alive: false };
				return resultStatus;
			});
	},
});

export default healthCheckSlice.reducer;
