import {
	TableBody as TableBodyMui,
	TableBodyProps as TableBodyPropsMui,
} from '@mui/material';

import styled from 'styled-components';

type TableBodyProps = TableBodyPropsMui;

const StyledTableBody = styled(TableBodyMui)({});

const TableBody: React.FC<TableBodyProps> = props => {
	return <StyledTableBody {...props}>{props.children}</StyledTableBody>;
};

export default TableBody;
