import {
	Dialog as DialogMui,
	DialogProps as DialogPropsMui,
} from '@mui/material';

import DialogActions from './DialogActions';
import DialogContent from './DialogContent';
import DialogTitle from './DialogTitle';
import styled from 'styled-components';

type DialogProps = DialogPropsMui & {
	title: string;
	actions: React.ReactNode;
	close: React.ReactNode;
	events: boolean;
};

const StyledDialog = styled(DialogMui)``;

const Dialog: React.FC<DialogProps> = props => {
	const { title, actions, close, events, ...dialogProps } = props;

	return (
		<StyledDialog {...dialogProps}>
			<DialogTitle title={title} children={close} />
			<DialogContent>{dialogProps.children}</DialogContent>
			{!!events && <DialogActions>{actions}</DialogActions>}
		</StyledDialog>
	);
};

export default Dialog;
