import { ListItemText as ListItemTextMui, ListItemTextProps as ListItemTextPropsMui } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

type ListItemTextProps = ListItemTextPropsMui

const StyledListItemText = styled(ListItemTextMui)``

const ListItemText:React.FC<ListItemTextProps> = props => {
    return <StyledListItemText {...props}>
        {props.children}
    </StyledListItemText>
}

export default ListItemText;