import BreadcrumbsMui from 'styles/breadcrumbs/Breadcrumbs';
import { ReactComponent as ChevronBreadCrumbIcon } from 'assett/icons/chevron_breadcrumb_icon.svg';
import Typography from 'styles/typography/Typograpy';
import palette from 'styles/theme/Palette';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useGetClaimStatusProperty } from 'hooks/utils.hook';

const ClaimStatusBreadcrumbs = ({ claimStatusId }: any) => {
	const { t: tDbLabels } = useTranslation('', { keyPrefix: 'dbLabels' });
	const getClaimStatus = useGetClaimStatusProperty();

	let pathnames: (1 | 3 | 4 | 9 | 5 | 10 | 11 | 12 | 13 | 14)[] = [
		1, 3, 4, 9, 5,
	]; //this is the normal workflow: pending doc, submitted, approved, sent for payments, paid

	if (claimStatusId === 13) {
		pathnames = [...pathnames, 13];
	}

	if (claimStatusId === 14) {
		pathnames = [...pathnames, 13, 14];
	}

	if ([2, 7, 8].includes(claimStatusId)) {
		//this is the rejected/void status. we show it withoud breadcrumbs
		return (
			<Typography
				variant="body"
				sx={{
					color: 'primary.main',
					textDecoration: 'none',
					fontWeight: '700',
					textUnderlineOffset: 5,
				}}
			>
				{claimStatusId ? tDbLabels(getClaimStatus(claimStatusId)?.label) : ''}
			</Typography>
		);
	}

	if ([10, 11, 12].includes(claimStatusId)) {
		pathnames = [10, 11, 12];
	}

	return (
		<BreadcrumbsMui separator={<ChevronBreadCrumbIcon />}>
			{pathnames.map((elStatus: any) => {
				return (
					<StyledBreadcrumbStep
						variant="body"
						$currentStep={claimStatusId === elStatus}
						key={elStatus}
					>
						{claimStatusId ? tDbLabels(getClaimStatus(elStatus)?.label) : ''}
					</StyledBreadcrumbStep>
				);
			})}
		</BreadcrumbsMui>
	);
};

const StyledBreadcrumbStep = styled(Typography)<
	typeof Typography & { $currentStep: boolean }
>`
	color: ${$currentStep =>
		({ $currentStep } ? palette.primary.main : palette.grey2.main)};
	font-weight: ${({ $currentStep }) => ($currentStep ? 700 : 'normal')};
`;

export default ClaimStatusBreadcrumbs;
