import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { claimStatusIds } from 'types/Claims';

export const useGetClaimStatusProperty = () => {
	const { claimsStatus } = useSelector((store: RootState) => store.claims);

	return (statusId: claimStatusIds) =>
		claimsStatus.find(el => el.id === statusId) || {
			id: 0,
			name: '',
			label: '',
			groupCode: '',
			groupLabel: '',
		};
};
