import {
	CardMedia as CarMediaMui,
	CardMediaProps as CardMediaPropsMui,
} from '@mui/material';

import styled from 'styled-components';

type CardMediaProps = CardMediaPropsMui & {
	component: string;
};

const StyledCardMedia = styled(CarMediaMui)<CardMediaProps>``;

const CardMedia: React.FC<CardMediaProps> = props => {
	return (
		<StyledCardMedia {...props} component={props.component}>
			{props.children}
		</StyledCardMedia>
	);
};

export default CardMedia;
