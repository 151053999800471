export const healthCheckThunk = async (_: any, thunkAPI: any) => {
	try {
		const response = await fetch(process.env.REACT_APP_CHECK_VPN_URL!);
		const checkBackend = await response.json();

		return checkBackend
			? true
			: thunkAPI.rejectWithValue({ response: 'dead ;(' });
	} catch (error: any) {
		return thunkAPI.rejectWithValue({ response: 'dead ;(' });
	}
};
