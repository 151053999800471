import {
	DialogContentText as DialogContentTextMui,
	DialogContentTextProps as DialogContentTextPropsMui,
} from '@mui/material';

import styled from 'styled-components';

type DialogContentTextProps = DialogContentTextPropsMui;

const StyledDialogContentText = styled(
	DialogContentTextMui,
)<DialogContentTextProps>``;

const DialogContentText: React.FC<DialogContentTextProps> = props => {
	return (
		<StyledDialogContentText {...props}>{props.children}</StyledDialogContentText>
	);
};

export default DialogContentText;
