import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

export const searchLocationThunk = async (_: any, thunkAPI: any) => {
	const { searchLocationValue } = thunkAPI.getState().search;
	const values = {
		name: searchLocationValue,
		size:10
	};

	try {
		const response = await authFetch.post('/provider/search', values);
		return response.data;
	} catch (error: any) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};


