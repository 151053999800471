import {
	IconButton as IconButtonMui,
	IconButtonProps as IconButtonPropsMui,
} from '@mui/material';

import styled from 'styled-components';

type IconButtonProps = IconButtonPropsMui;

const StyledIconButton = styled(IconButtonMui)<IconButtonProps>``;

const IconButton: React.FC<IconButtonProps> = props => {
	return <StyledIconButton {...props}>{props.children}</StyledIconButton>;
};

export default IconButton;
