import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TInputStateSlice } from 'types/Form';

const initialState: TInputStateSlice = {
	reasonNote: null,
	reasonCode: null,
};

const inputSlice = createSlice({
	name: 'inputSlice',
	initialState,
	reducers: {
		setInputHandleChange: (
			state: TInputStateSlice,
			action: PayloadAction<{
				name: keyof TInputStateSlice;
				value: string | null;
			}>,
		) => {
			state[action.payload.name as keyof TInputStateSlice] = action.payload.value;
		},
		resetInputStore: () => initialState,
	},
});

export const { setInputHandleChange, resetInputStore } = inputSlice.actions;
export default inputSlice.reducer;
