import { Paper as PaperMui, PaperProps as PaperPropsMui } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

type PaperProps = PaperPropsMui

const StyledPaper = styled(PaperMui)({})

const Paper:React.FC<PaperProps> = props => {
    return <StyledPaper {...props} >
        {props.children}
    </StyledPaper>
}

export default Paper;