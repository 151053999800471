import { MenuItem as MenuItemMui, MenuItemProps as MenuItemPropsMui } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

type MenuItemProps = MenuItemPropsMui

const StyledMenuItem = styled(MenuItemMui)``

const MenuItem:React.FC<MenuItemProps> = props => {
    return <StyledMenuItem {...props}>
        {props.children}
    </StyledMenuItem>
}

export default MenuItem;