import {
	Checkbox as CheckboxMui,
	CheckboxProps as CheckboxPropsMui,
	FormControlLabel,
	Typography,
} from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as CheckboxCheckedIcon } from 'assett/icons/checkboxChecked_icon.svg';
import { ReactComponent as CheckboxIcon } from 'assett/icons/checkbox_con.svg';

type CheckboxProps = CheckboxPropsMui;

const StyledCheckbox = styled(CheckboxMui)``;

const StyledFormControlLabel = styled(FormControlLabel)``;

const StyledTypography = styled(Typography)``;

const Checkbox: React.FC<CheckboxProps & { label: string }> = props => {
	return (
		<StyledFormControlLabel
			label={<StyledTypography variant="value2">{props.label}</StyledTypography>}
			control={
				<StyledCheckbox
					{...props}
					icon={<CheckboxIcon />}
					checkedIcon={<CheckboxCheckedIcon />}
					indeterminateIcon={<CheckboxIcon />}
					disableRipple={true}
				/>
			}
		/>
	);
};

export default Checkbox;
